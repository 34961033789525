/**
 * 订单列表相关API
 */

import request from '@/utils/request'

/**
 * 获取订单列表
 * @param params
 */
export function tradeOrders(params) {
  return request({
    url: '/seller/trade/orders',
    method: 'get',
    loading: false,
    params
  })
}

/**
 * 查询订单出行人及紧急联系人
 * @param order_id
 */
export function tradeOrdersTAndE(order_id) {
  return request({
    url: `/seller/trade/orders/${order_id}/TAndE`,
    method: 'get',
    loading: false
  })
}

/**
 * 查询单个订单明细
 * @param order_id
 */
export function tradeOrdersOne(order_id) {
  return request({
    url: `/seller/trade/orders/${order_id}`,
    method: 'get',
    loading: false
  })
}

/**
 * 修改订单备注信息
 * @param order_id,params
 */
export function tradeOrdersRemark(order_id, params) {
  return request({
    url: `/seller/trade/orders/${order_id}/remark`,
    method: 'put',
    loading: false,
    params
  })
}

/**
 * 导出订单列表
 * @param params
 */
export function tradeOrdersExport(params) {
  return request({
    url: '/seller/trade/orders/export',
    method: 'get',
    params
  })
}

/**
 * 对一个交易发起退款
 * @param params
 */
export function NewrefundPayTrade(params) {
  return request({
    url: `/seller/after-sales/newrefund/payTrade/${params.sn}`,
    method: 'get',
    params
  })
}

/**
 * 获取售后服务详细信息
 * @param order_sn
 */
export function NewrefundDetail(order_sn) {
  return request({
    url: `/seller/after-sales/newrefund/detail/${order_sn}`,
    method: 'get'
  })
}

/**
 * 审核退款
 * @param id
 */
export function NewrefundAuditRefund(params) {
  return request({
    url: `/seller/after-sales/newrefund/auditRefund/${params.id}`,
    method: 'post',
    params
  })
}
